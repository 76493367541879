import "./navigation.css";

function Navigation() {
  return (
    <ul className="nav container fixed-top">
      <li className="nav-item">
        <a className="nav-link active" href="#home">
          Home
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#about">
          About
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#works">
          Work
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#pics">
          Pics
        </a>
      </li>
    </ul>
  );
}
export default Navigation;
