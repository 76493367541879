import "./home.css";
import React from "react";
import { Element } from "react-scroll";

function Home() {
  return (
    <div>
      <Element id="home" className="section home">
        <div className="background">
          <h1 className="beginning-title">Hot potatoes</h1>
        </div>
        {/* <div className="half-block" /> */}
      </Element>
    </div>
  );
}

export default Home;
