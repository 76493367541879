import React from "react";
import { Element } from "react-scroll";
import "./pics.css";

function Pics() {
  return (
    <Element id="pics" className="section">
      <h1 className="title">Goofies</h1>
      <div className="container">
        <div className="row">
          <img src="./IMG_0919.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2018.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2506.jpeg" className="col-sm mini-pic"></img>
        </div>
        <div className="row">
          <img src="./IMG_2226.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2400.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2417.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2561.jpeg" className="col-sm mini-pic"></img>
        </div>
        <div className="row">
          <img src="./IMG_1527.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_1820.jpeg" className="col-sm mini-pic"></img>
          <img src="./IMG_2988.jpeg" className="col-sm mini-pic"></img>
        </div>
      </div>
    </Element>
  );
}

export default Pics;
