import "./home.css";
import React from "react";
import { Element } from "react-scroll";
import "./about.css";

function About() {
  return (
    <Element id="about" className="section">
      <h1 className="title">Nicole Lu</h1>
      <div className="row" style={{ alignItems: "center" }}>
        <img src="IMG_2242.jpeg" alt="profile" className="profile col" />
        <ul className="col">
          <li>Hello!</li>
        </ul>
      </div>
    </Element>
  );
}

export default About;
