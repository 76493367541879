import React, { useEffect, useRef } from "react";
import Navigation from "./navigation";
import Home from "./home";
import About from "./about";
import Works from "./works";
import Pics from "./pics";
import "./index.css";

function throttle(callback, delay) {
  let lastTime = 0;
  return function () {
    const currentTime = new Date().getTime();
    if (currentTime - lastTime >= delay) {
      callback.apply(null, arguments);
      lastTime = currentTime;
    }
  };
}

function App() {
  const videoRef = useRef(null);
  let bg_num = 2;
  useEffect(() => {
    const video = videoRef.current;
    const handleScroll = throttle(() => {
      const scrollPosition = window.scrollY;
      video.playbackRate = 0.2;
      video.currentTime = scrollPosition * 0.001; // adjust for smoothness and length
    }, 100);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <Navigation />
      <video className="background-video" ref={videoRef} loop muted>
        <source src={"./background-test2.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Home />
      <div style={{ height: 1000 }} />
      <About />
      <div style={{ height: 1000 }} />
      <Works />
      <div style={{ height: 1000 }} />
      <Pics />
    </div>
  );
}

export default App;
