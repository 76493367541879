import "./works.css";
import React, { useRef, useEffect } from "react";
import { Element } from "react-scroll";

function Works() {
  const backRef = useRef(null);
  const nextRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const next = nextRef.current;
    const back = backRef.current;

    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
      scrollContainer.style.scrollBehavior = "auto";
    });

    next.addEventListener("click", () => {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft += 500;
    });

    back.addEventListener("click", () => {
      scrollContainer.style.scrollBehavior = "smooth";
      scrollContainer.scrollLeft -= 500;
    });
  });
  return (
    <Element
      id="works"
      className="section"
      style={{ marginTop: 20, backgroundColor: "#f0f0f0" }}
    >
      <h1 className="title">Work</h1>
      <h1 className="title">Art Progression</h1>
      <div className="gallery-wrap">
        <span>
          <button id="backBtn" ref={backRef}>
            &lt;
          </button>
        </span>
        <div className="gallery" ref={scrollContainerRef}>
          <nobr>
            <span>
              <img
                // height: 220, width: "auto"
                style={{ height: 220, width: "auto" }}
                src="./woah2.png"
                alt="Fingers"
                className="artwork"
              />
            </span>
            <span>
              <img
                style={{ height: 220, width: "auto" }}
                src="mushroom wastelands.png"
                alt="mushroom wastelands"
                className="artwork"
              />
            </span>
            <span>
              <img
                style={{ height: 220, width: "auto" }}
                src="./Torch.jpeg"
                alt="Torch lighting"
                className="artwork"
              />
            </span>
            <span>
              <img
                style={{ height: 220, width: "auto" }}
                src="./Character_group.png"
                alt="character group drawing"
                className="artwork"
              />
            </span>
            <span>
              <img
                style={{ height: 220, width: "auto" }}
                src="./Tomboy.png"
                alt="Tomboy on a chair"
                className="artwork"
              />
            </span>
          </nobr>
        </div>
        <span>
          <button id="nextBtn" ref={nextRef}>
            &gt;
          </button>
        </span>
      </div>
      <h1 className="title">Programming Projects</h1>
      <a href="https://codepen.io/Toumayitouh/full/yLwJRBq" target="_blank">
        <img className="websites" src="./food-website.png" alt="food website" />
      </a>
      <a href="https://chathep.org" target="_blank">
        <img className="websites" src="./chathep.png" alt="chathep website" />
      </a>
      <h1
        className="title"
        style={{
          height: "50vh",
        }}
      >
        Video Production
      </h1>
    </Element>
  );
}

export default Works;
